import { FC, useState } from 'react';
import Cookies from 'js-cookie';

import { Button, toast } from '@/shared/components';
import { DocumentDownloadIcon } from '@/shared/icons';
import { useQueryParams } from '@/shared/hooks';
import {
  API_BASE_URL,
  FILTERS_TABLE_KEY,
  GENERAL_ERROR_MESSAGE,
  PAGINATION_KEY,
  SORT_TABLE_KEY,
} from '@/shared/constants';
import { ACCESS_TOKEN_KEY } from '@/features/auth/constants';
import { fileBlobDownload } from '@/shared/utils/files';
import { jsonStringify } from '@/shared/utils';

import styles from './export-button.module.scss';

type ExportButtonProps = {
  disabled: boolean;
  endpoint: string;
};

export const ExportButton: FC<ExportButtonProps> = ({ disabled, endpoint }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { queryParams } = useQueryParams();

  const preparedQueryParams = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    [FILTERS_TABLE_KEY]: queryParams[FILTERS_TABLE_KEY],
    [SORT_TABLE_KEY]: queryParams[SORT_TABLE_KEY],
    [PAGINATION_KEY]: jsonStringify({}),
  };

  const downloadReport = () => {
    const apiUrl = `${API_BASE_URL}${endpoint}?${new URLSearchParams(preparedQueryParams).toString()}`;

    const accessToken = Cookies.get(ACCESS_TOKEN_KEY);

    if (!accessToken) return;

    setIsLoading(true);

    const onError = (e?: Error) => {
      console.error(e);
      setIsLoading(false);
      toast.error(GENERAL_ERROR_MESSAGE);
    };

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    fetch(apiUrl, options)
      .then((response) => {
        if (response.ok) {
          response
            .blob()
            .then((data) => {
              setIsLoading(false);
              fileBlobDownload(data, response.headers);
            })
            .catch(onError);
        } else {
          onError();
        }
      })
      .catch(onError);
  };

  return (
    <Button className={styles['button']} onClick={downloadReport} disabled={disabled || isLoading}>
      <DocumentDownloadIcon /> <span className={styles['button-text']}>{isLoading ? 'Exporting...' : 'Export'}</span>
    </Button>
  );
};
