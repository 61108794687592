export const fileBlobDownload = (file: Blob, headers: Headers, customFileName?: string): void => {
  let filename = 'file.xlsx';
  const disposition = headers.get('content-disposition');

  if (customFileName) {
    filename = customFileName;
  } else if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  const downloadUrl = URL.createObjectURL(file);

  if (filename) {
    // use HTML5 a[download] attribute to specify filename
    const a = document.createElement('a');
    // safari doesn't support this yet
    if (typeof a.download === 'undefined') {
      window.location.href = downloadUrl;
    } else {
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }
  } else {
    window.location.href = downloadUrl;
  }

  setTimeout(function () {
    URL.revokeObjectURL(downloadUrl);
  }, 100); // cleanup
};
