import { FC, useState } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';

import { Button, Logo } from '@/shared/components';
import { APP_LINKS } from '@/configs';
import { LogoutIcon, Menu } from '@/shared/icons';
import { useAuth } from '@/features/auth';

import { linkConfig } from './link-config';
import { LinkDropdown } from './link-dropdown';
import { NavLink } from './nav-link';

import styles from './authorized-header.module.scss';

const USER_MANAGEMENT_ROUTES: string[] = [APP_LINKS.USER_MANAGEMENT__CUSTOMERS, APP_LINKS.USER_MANAGEMENT__HANDS];

export const AuthorizedHeader: FC = () => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(true);

  const { pathname } = useLocation();

  const isUserManagementRoute = !!USER_MANAGEMENT_ROUTES.filter((route) => route.match(pathname)).length;

  const { logout } = useAuth();

  const handleMobileMenu = () => {
    setIsOpenMenuMobile((prev) => !prev);
  };

  return (
    <header className={clsx(styles['header'])}>
      <div className={clsx(styles['header-logo'])}>
        <Link className={styles['menu-logo']} to={APP_LINKS.DASHBOARD}>
          <Logo />
        </Link>

        <Menu className={styles['menu-icon']} onClick={handleMobileMenu} />
      </div>
      <nav className={clsx(styles['navigation'], isOpenMenuMobile && styles['is-mobile'])}>
        <ul className={clsx(styles['list'])}>
          {linkConfig.map(({ label, link, links }, i) => (
            <li key={i} className={styles['list-item']}>
              {links ? (
                <LinkDropdown
                  isOpenDropdown={isOpenDropdown}
                  setIsOpenDropdown={setIsOpenDropdown}
                  triggerLabel={label}
                  isActive={isUserManagementRoute}
                  triggerClassName={clsx(styles['list-item-link'], isUserManagementRoute && styles['active'])}
                >
                  {links.map(({ link, label }, i) => (
                    <NavLink
                      key={i}
                      className={styles['list-item-link']}
                      activeClassName={styles['active']}
                      to={link}
                      onClick={() => {
                        setIsOpenDropdown(false);
                        handleMobileMenu();
                      }}
                    >
                      {label}
                    </NavLink>
                  ))}
                </LinkDropdown>
              ) : (
                <NavLink
                  key={i}
                  className={styles['list-item-link']}
                  activeClassName={styles['active']}
                  to={link}
                  onClick={handleMobileMenu}
                >
                  {label}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
        <Button variant="text" noPadding className={styles['logout-button']} onClick={logout}>
          <LogoutIcon className={styles['logout-button-icon']} width="1.5rem" height="1.5rem" />
          <span className={styles['logout-button-label']}>Log out</span>
        </Button>
      </nav>
    </header>
  );
};
